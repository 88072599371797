<template>
  <div class="card mb-5 mb-xl-10">
    <div class="card-body pt-9 pb-0">
      <!--begin::Details-->
      <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
        <!--begin: Pic-->
        <div class="me-7 mb-4">
          <div
            class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
          >
            <img src="/media/illustrations/dozzy-1/7.png" alt="image" />
          </div>
        </div>
        <!--end::Pic-->

        <!--begin::Info-->
        <div class="flex-grow-1">
          <!--begin::Title-->
          <div
            class="d-flex justify-content-between align-items-start flex-wrap mb-2"
          >
            <!--begin::User-->
            <div class="d-flex flex-column">
              <!--begin::Name-->
              <div class="d-flex align-items-center mb-2">
                <a
                  href="#"
                  class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                >Daily Ad Cost Control</a
                >

                <a
                  href="#"
                  class="btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_upgrade_plan"
                >Recommended for you</a
                >
              </div>
              <!--end::Name-->

              <!--begin::Info-->
              <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                <a
                  href="#"
                  class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg src="/media/svg/icons/General/User.svg" />
                  </span>
                  Charlie T.
                </a>
              </div>
              <!--end::Info-->
            </div>
            <!--end::User-->

            <!--begin::Actions-->
            <div class="d-flex my-4">

              <a
                href="#"
                class="btn btn-sm btn-primary me-3"
              >Use Strategy</a
              >

            </div>
            <!--end::Actions-->
          </div>
          <!--end::Title-->

          <!--begin::Stats-->
          <div class="d-flex flex-wrap flex-stack">
            <!--begin::Wrapper-->
            <div class="d-flex flex-column flex-grow-1 pe-8">
              <!--begin::Stats-->
              <div class="d-flex flex-wrap">
                <!--begin::Stat-->
                <div
                  class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <div
                      class="fs-2 fw-bolder"
                      data-kt-countup="true"
                      data-kt-countup-value="4500"
                      data-kt-countup-prefix="$"
                    >
                      2.49M
                    </div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-gray-400">Spend (30 days)</div>
                  <!--end::Label-->
                </div>
                <!--end::Stat-->

                <!--begin::Stat-->
                <div
                  class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <div
                      class="fs-2 fw-bolder"
                      data-kt-countup="true"
                      data-kt-countup-value="75"
                    >
                      39
                    </div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-gray-400">Followers</div>
                  <!--end::Label-->
                </div>
                <!--end::Stat-->

                <!--begin::Stat-->
                <div
                  class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <div
                      class="fs-2 fw-bolder"
                      data-kt-countup="true"
                      data-kt-countup-value="23.5"
                      data-kt-countup-prefix="%"
                    >
                      23.7K
                    </div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-gray-400">Actions (30 days)</div>
                  <!--end::Label-->
                </div>
                <!--end::Stat-->
              </div>
              <!--end::Stats-->
            </div>
            <!--end::Wrapper-->

            <!--begin::Progress-->
            <div class="mt-auto mb-2">
              <span class="badge badge-light me-5">ROAS Control</span>
              <span class="badge badge-light me-5">Daily Management</span>
              <span class="badge badge-light me-5">Budget/Bid Control</span>
            </div>
            <!--end::Progress-->
          </div>
          <!--end::Stats-->
        </div>
        <!--end::Info-->
      </div>
      <!--end::Details-->

    </div>
  </div>
  <div class="row g-6 g-xl-9 mb-xl-10">
    <div class="col-lg-6 col-xxl-4">
      <div class="card card-xxl-stretch mb-5 mb-xl-10">
        <SpendCPA></SpendCPA>
      </div>
    </div>
    <div class="col-lg-6 col-xxl-4">
      <div class="card card-xxl-stretch mb-5 mb-xl-10">
        <ROAS></ROAS>
      </div>
    </div>
    <div class="col-lg-6 col-xxl-4">
      <div class="card card-xxl-stretch mb-5 mb-xl-10">
        <div :class="widgetClasses" class="card">
          <!--begin::Body-->
          <div class="card-body">
            <div class="d-flex flex-stack flex-grow-1 card-p">
              <div class="d-flex flex-column me-2">
                <span href="#" class="text-dark text-hover-primary fw-bolder fs-3">Usage by Niche</span>

              </div>

            </div>

            <!--begin::Chart-->
            <apexchart type="donut" :options="donutOptions" :series="donutSeries" height="350"></apexchart>
            <!--end::Chart-->
          </div>
          <!--end::Body-->
        </div>

      </div>
    </div>
  </div>
  <div class="card">
    <!--begin::Body-->
    <div class="card-body p-10 p-lg-15">
      <!--begin::Content main-->
      <div class="mb-14">
        <!--begin::Heading-->
        <!--end::Heading-->
        <!--begin::Body-->
        <!--begin::Table-->
        <!--end::Table-->
        <!--begin::Block-->
        <div class="mb-5 pb-lg-5">
          <!--begin::Title-->
          <h2 class="fw-bolder text-dark mb-8">Strategy Overview</h2>
          <div class="fs-4 fw-bold text-gray-700 mb-5">Control your daily marketing cost by activating and pausing ads based on 3 data sources:
          </div>
          <ul class="fs-4 fw-bold mb-6">
            <li>
              <span class="text-gray-700">Facebook</span>
            </li>
            <li class="my-2">
              <span class="text-gray-700">Internal pixel</span>
            </li>
            <li>
              <span class="text-gray-700">Ecommerce platform (sales)</span>
            </li>
          </ul>
          <!--end::List-->
          <!--begin::Text-->
          <div class="fs-4 fw-bold text-gray-700 mb-5">This strategy acts as an accordion, releasing good ads when margin are high and pausing low performing ads when margins are low.
            It includes:</div>
          <div>
            <ul class="fs-4 fw-bold mb-3">
              <li>
                <span class="text-gray-700">Daily pause of ads</span>
              </li>
              <li>
                <span class="text-gray-700">Stop-loss</span>
              </li>
              <li>
                <span class="text-gray-700">Aggressive ad control</span>
              </li>
              <li>
                <span class="text-gray-700">Budget & bid changes</span>
              </li>
            </ul>
          </div>
          <!--end::Text-->
          <!--begin::Title-->

          <!--end::Icon-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import SpendCPA from "@/unused/components/widgets/charts/SpendCPA.vue";
import ROAS from "@/unused/components/widgets/charts/ROAS.vue";
import {mapActions, mapGetters} from "vuex";

export default defineComponent({
  name: "strategy",
  components: {
    SpendCPA,
    ROAS
    // MixedWidget1
  },
  data() {
    return {
      title: 'The name',
      description: 'This is some description',
      donutOptions: {
        labels: ['Jewelery', 'Fashion', 'Electronics', 'Homeware']
      },
      donutSeries: [20.6, 67.3, 3.9, 15],
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Daily Ad Cost Control", ['Strategies']);
    });
  }
});
</script>
