
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import SpendCPA from "@/unused/components/widgets/charts/SpendCPA.vue";
import ROAS from "@/unused/components/widgets/charts/ROAS.vue";
import {mapActions, mapGetters} from "vuex";

export default defineComponent({
  name: "strategy",
  components: {
    SpendCPA,
    ROAS
    // MixedWidget1
  },
  data() {
    return {
      title: 'The name',
      description: 'This is some description',
      donutOptions: {
        labels: ['Jewelery', 'Fashion', 'Electronics', 'Homeware']
      },
      donutSeries: [20.6, 67.3, 3.9, 15],
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Daily Ad Cost Control", ['Strategies']);
    });
  }
});
